import { Controller as BaseController } from '@hotwired/stimulus';

export default class extends BaseController<HTMLElement> {
    static targets = ['regular', 'custom', 'amount', 'amountContainer'];

    declare regularTargets: HTMLInputElement[];
    declare customTargets: HTMLInputElement[];
    declare amountTarget: HTMLInputElement;
    declare amountContainerTarget: HTMLElement;

    connect() {
        super.connect();

        this.regularTargets.map((target) => {
            target.addEventListener('change', () => {
                this.amountContainerTarget.classList.add('invisible');
                this.amountTarget.value = '';
            });
        });

        this.customTargets.map((target) => {
            target.addEventListener('change', () => {
                this.amountContainerTarget.classList.remove('invisible');
                this.amountTarget.focus();
            });
        });
    }

    disconnect() {
        super.disconnect();
    }
}
