import {Controller as BaseController} from "@hotwired/stimulus"
import {Html5QrcodeScanner} from "html5-qrcode";
import {Html5QrcodeError} from "html5-qrcode/core";
import {Html5QrcodeScannerState} from "html5-qrcode/src/state-manager";
import KeyboardReader from "./keyboard_reader";

export default class extends BaseController<HTMLElement> {
  private readonly configKeys = {
    usingCamera: "kiosk/checkout/usingCamera"
  };

  static values = {
    enabled: Boolean,
  }

  static targets = ["camera", "presence"]

  declare readonly enabledValue: boolean
  declare readonly hasCameraTarget: boolean
  declare readonly cameraTarget: HTMLElement
  declare readonly presenceTarget: HTMLInputElement

  declare paused: boolean
  declare cameraScanner: Html5QrcodeScanner
  declare keyboardReader: KeyboardReader
  declare cameraDashboardInitiallyHidden: boolean;

  connect() {
    if (!this.enabledValue) {
      return
    }

    this.cameraDashboardInitiallyHidden = false
    this.paused = false

    this.initializeCamera()
    this.initializeKeyboardReader()
  }

  disconnect() {
    if (!this.enabledValue) {
      return
    }

    this.stopCamera()
    this.keyboardReader.stop()
  }

  async onScan(value: string) {
    console.log("onScan", value)
    const [type, token] = value.split(':')
    if (type === 'presence') {
      this.presenceTarget.value = token
      this.presenceTarget.form!.requestSubmit()
    } else if (type === 'voucher') {
      this.presenceTarget.value = value
      this.presenceTarget.form!.requestSubmit()
    }
  }

  initializeCamera() {
    if (!this.hasCameraTarget) {
      return
    }

    console.log("Initializing camera")
    if (this.usingCamera) {
      this.startCamera();
    }
  }

  onCameraScanSuccess(text: string, result: any) {
    if (this.paused) {
      return
    }
    this.paused = true

    console.log(`Scan success: ${text}`, result);

    this.onScan(text)
  }

  onCameraScanError(message: string, error: Html5QrcodeError) {
    if (message == "QR code parse error, error = NotFoundException: No MultiFormat Readers were able to detect the code.") {
      return;
    }
    if (message == "QR code parse error, error = No barcode or QR code detected.") {
      return;
    }
    console.log(`Scan error: ${message}`, error);
  }

  get usingCamera() {
    return localStorage.getItem(this.configKeys.usingCamera) == "true";
  }

  set usingCamera(value: boolean) {
    const beforeValue = this.usingCamera;
    localStorage.setItem(this.configKeys.usingCamera, value.toString());

    console.log("Setting usingCamera to ", value)

    if (beforeValue && !value) {
      this.stopCamera()
    } else if (!beforeValue && value) {
      this.startCamera()
    }
  }

  toggleCamera(event: Event) {
    this.usingCamera = !this.usingCamera;
  }

  startCamera() {
    if (!this.hasCameraTarget) {
      return
    }

    this.cameraScanner = new Html5QrcodeScanner(
      "camera",
      {
        fps: 10,
        qrbox: 250,
      },
      false,
    );
    this.cameraScanner.render(
      this.onCameraScanSuccess.bind(this),
      this.onCameraScanError.bind(this),
    );

    setTimeout(() => {
        if (!this.cameraDashboardInitiallyHidden) {
            const cameraDashboard = document.getElementById('camera__dashboard')
            if (cameraDashboard) {
                cameraDashboard.classList.toggle("hidden");

                const startCameraButtonElement = document.getElementById('html5-qrcode-button-camera-start')
                if (startCameraButtonElement) {
                    startCameraButtonElement.click()
                }

                this.cameraDashboardInitiallyHidden = true;
            }
        }

        const buttonElement = document.getElementById('html5-qrcode-button-camera-permission')
        if (buttonElement) {
            buttonElement.click()
        }
    }, 1000);


      this.cameraTarget.classList.remove("hidden");
  }

  async stopCamera() {
    if (!this.hasCameraTarget) {
      return
    }

    if (this.cameraScanner) {
      if (this.cameraScanner.getState() == Html5QrcodeScannerState.SCANNING) {
        this.cameraScanner.pause(true);
      }
      await this.cameraScanner.clear();
    }

    this.cameraTarget.classList.add("hidden");
  }

  toggleCameraDashboard() {
      const cameraDashboard = document.getElementById("camera__dashboard");
      cameraDashboard?.classList.toggle("hidden");
  }

  initializeKeyboardReader() {
    console.log("Initializing keyboard reader")
    this.keyboardReader = new KeyboardReader({
      onScan: this.onScan.bind(this),
      onStateChange: (state) => {
        console.log("KeyboardReader state changed to ", state)
      }
    });
    this.keyboardReader.start()
  }
}
